<template>
  <div>
    <b-overlay
      spinner-variant="danger"
      variant="dark"
      z-index="900"
      fixed
      :show="generatingPdf"
      no-wrap
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="SalesReport"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="onProgress"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-3">
        <sales-report-export :items="exportItems" />
      </section>
    </vue-html2pdf>

    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-4">
          <b-button class="mb-5" variant="primary" @click="prepareSaleLink">{{
            $t("ROUTES.NEW_SALE")
          }}</b-button>
          <div class="d-flex flex-row">
            <b-button class="mr-3 mb-5" variant="info" @click="generatePDF">{{
              $t("PAGES.SALES_MANAGEMENT.LIST.EXPORT_TO_PDF")
            }}</b-button>
            <JsonExcel
              v-for="mimeType in ['xls', 'csv']"
              :key="mimeType"
              :name="`SalesReport.${mimeType}`"
              :fetch="() => generateExcel(mimeType)"
              :fields="excelFields"
              :type="mimeType"
              :before-generate="
                () => {
                  generatingPdf = true;
                }
              "
              :before-finish="
                () => {
                  generatingPdf = false;
                }
              "
            >
              <b-button
                class="mr-3 mb-5"
                :variant="mimeType === 'xls' ? 'success' : 'primary'"
              >
                {{
                  mimeType === "xls"
                    ? $t("PAGES.SALES_MANAGEMENT.LIST.EXPORT_TO_EXCEL")
                    : "CSV"
                }}</b-button
              >
            </JsonExcel>
          </div>
        </div>
        <DatePickers
          :filters="filters"
          @option-selected="onOptionSelected"
          @on-start-date-selected="onStartDateSelected"
          @on-end-date-selected="onEndDateSelected"
          @on-all-dates-selected="onAllDatesSelected"
        >
          <template v-slot:filters>
            <list-filter
              v-for="(filter, filterIndex) in filters"
              :key="filterIndex"
              :filter="filter"
              :filter-index="filterIndex"
              @option-selected="onOptionSelected"
            />
          </template>
        </DatePickers>

        <div class="row align-items-center mb-5">
          <div class="col-md-8 col-sm-12">
            &nbsp;
            <TableFilter @filter-change="onFilterChange" />
          </div>
          <div class="col-md-4 col-sm-12">
            {{ $t("ECOMMERCE.COMMON.FILTER") }}
            <b-form-select
              v-model="filterIncludedFields"
              :options="filterIncludedFieldsArray"
              text-field="text"
              value-field="field"
            >
            </b-form-select>
          </div>
        </div>

        <b-table
          ref="listTable"
          id="orders-table"
          primary-key="id"
          :items="fetchData"
          :fields="fields"
          :filter="filter"
          selectable
          select-mode="single"
          :busy.sync="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          @row-selected="onRowSelected"
          @refreshed="onRefreshed"
          responsive
          stacked="md"
        >
          <template #table-busy>
            <div class="text-center text-danger my-5">
              <b-spinner class="align-middle busy-spinner"></b-spinner>
            </div>
          </template>

          <template #cell(nexusAmount)="data">
            {{ getPaidCryptoQuantity(data.item) }}
          </template>
          <template #cell(price)="data">
            {{ data.value }}
          </template>
          <template #cell(paymentUnitName)="data">
            {{ data.value }}
            <!-- {{ getPaymentUnitName(data.item) }} -->
          </template>
          <template #cell(fiat)="data">
            {{ data.value ? "Fiat" : "Crypto" }}
          </template>
          <template #cell(selectedCurrencyCode)="data">
            {{ data.item.selectedCurrencyCode }}
            <!--            {{ data.value }}-->
          </template>
          <template #cell(platform)="data">
            {{ getPlatform(data.item) }}
          </template>

          <template #cell(fee)="data">
            <span class="d-inline-flex align-items-center">
              <b-icon-percent></b-icon-percent>{{ data.value }}
              <span
                v-b-popover.hover.top="
                  getCommissionPayerLabel(data.item.feeIncluded)
                "
              >
                ({{
                  getCommissionPayerLabel(data.item.feeIncluded).substring(
                    0,
                    1
                  )
                }})
              </span>
            </span>
          </template>

          <template #cell(commissionConvertCurrency)="data">
            <span class="d-inline-flex align-items-center">
              {{ data.value }}
              <b-icon
                icon="check"
                font-scale="2"
                class="common-icon-color"
                variant="success"
                v-if="data.item.commissionStatus"
              ></b-icon>
              <b-icon icon="x" v-else font-scale="2" class="text-dark"></b-icon>
            </span>
          </template>

          <template #cell(orderTotal)="data">
            {{ data.value }}
          </template>

          <template #cell(orderStatus)="data">
            <span class="d-inline-flex align-items-center">
              <span
                class="badge mr-1"
                :class="[getOrderBadgeAndLabel(data.item.status).itemClass]"
              >
                {{ getOrderBadgeAndLabel(data.item.status).itemLabel }}
              </span>
              <button
                v-if="`${data.item.status}` === '5'"
                class="payment-button"
                @click="completePayment(data.item)"
              >
                <div
                  class="badge badge-secondary py-1 d-inline-flex justify-content-center align-items-center"
                >
                  <span class="p-0">{{ $t("complete") }}</span>
                  <b-icon-check2 variant="success"></b-icon-check2>
                </div>
              </button>
              <!-- <button
                v-if="data.item.orderStatus === 'pending'"
                class="payment-button"
                @click="preparePayment($event, data.item)"
              >
                <b-icon-caret-right-fill
                  variant="primary"
                ></b-icon-caret-right-fill>
              </button>
              <button v-else class="payment-button cursor-default">
                <b-icon-check font-scale="2" variant="success"></b-icon-check>
              </button> -->
            </span>
          </template>
        </b-table>

        <div class="row align-items-center mt-5">
          <h6 class="col-12">
            {{
              $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMPLETED_ORDERS_AMOUNT")
            }}
          </h6>
          <div class="col-12 align-items-center">
            <div
              v-for="(orderTotalItem, idx) in ordersTotal"
              :key="`orderTotal-${idx}-${orderTotalItem.id}`"
              class="d-flex flex-row justify-content-between"
            >
              <span class="col-4">{{ orderTotalItem.name }}</span>
              <span class="col-4">{{ orderTotalItem.orderTotal }}</span>
              <span class="col-4"
                >({{
                  $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDERS_COUNT", {
                    count: orderTotalItem.ordersCount
                  })
                }})</span
              >
            </div>
          </div>
          <div class="mt-5 col-12 pt-5">
            <b-pagination
              align="fill"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="orders-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import TableFilter from "@/components/list/TableFilter.vue";
import DatePickers from "@/components/list/DatePickers.vue";
import { ListMixin } from "@/mixins/list.mixin";
import SalesReportExport from "@/components/list/SalesReportExport.vue";
import JsonExcel from "vue-json-excel";
import { FILTER_TYPE } from "@/constants";
import ListFilter from "@/components/list/ListFilter.vue";
import Swal from "sweetalert2";

export default {
  components: {
    TableFilter,
    DatePickers,
    VueHtml2pdf,
    SalesReportExport,
    JsonExcel,
    ListFilter
  },
  mixins: [ListMixin],
  data() {
    return {
      filterIncludedFieldsArray: [
        {
          text: this.$t("PAGES.SALES_MANAGEMENT.LIST.ORDER_ID"),
          field: "orderNumber"
        },
        {
          text: this.$t("PAGES.SALES_MANAGEMENT.LIST.SELECTED_CURRENCY_CODE"),
          field: "selectedCurrencyCode"
        },
        {
          text: this.$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER"),
          field: "platform"
        },
        {
          text: this.$t("PAGES.SALES_MANAGEMENT.LIST.PAYMENT_UNIT_NAME"),
          field: "paymentUnit.name"
        }
      ],
      filterIncludedFields: "orderNumber",
      generatingPdf: false,
      exportItems: [],
      filters: [
        /* {
          text: "Fiat",
          field: "fiat",
          type: FILTER_TYPE.FIAT,
          currentValue: undefined
        }, */
        {
          text: "PAGES.SALES_MANAGEMENT.LIST.STATUS",
          field: "status",
          type: FILTER_TYPE.ORDER_STATUS,
          currentValue: undefined
        }
      ],
      fields: [
        {
          key: "_id",
          label: "ID",
          sortable: true
        },
        {
          key: "orderNumber",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.ORDER_ID"),
          sortable: true
        },
        {
          key: "selectedCurrencyCode",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CRYPTO_CURRENCY_CODE")
        },
        {
          key: "amount",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CRYPTO_QUANTITY")
        },
        {
          key: "nexusAmount",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CRYPTO_QUANTITY_PAID")
        },
        {
          key: "paymentUnitName",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PAYMENT_UNIT")
        },
        {
          key: "price",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PRICE"),
          sortable: true
        },
        {
          key: "fee",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.COMMISSION"),
          sortable: true
        },
        {
          key: "commissionConvertCurrency",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.SERVICE_COMMISSION"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "orderTotal",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.TOTAL")
        },
        /* {
          key: "fiat",
          label: "Fiat"
        }, */
        {
          key: "createdAt",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.DATE_TIME"),
          sortable: true,
          formatter: value => {
            return this.$dateTimeFilter(value, "date-time");
          }
        },
        {
          key: "platform",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER"),
          sortable: true
        },
        {
          key: "orderStatus",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.STATUS"),
          sortable: true
        }
      ],
      excelFields: {},
      excelType: "xls",
      ordersTotal: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SALES_MANAGEMENT") },
      { title: this.$t("ROUTES.ALL_SALES") }
    ]);
    this.fetchOrdersTotals();
  },
  computed: {
    ...mapGetters({
      mainCompanyId: "orders/MAIN_COMPANY_ID"
    }),
    fetchUrl() {
      // console.log(`companies/${this.mainCompanyId}/company-orders`)
      return `companies/${this.mainCompanyId}/company-orders`;
    }
  },
  methods: {
    async fetchOrdersTotals() {
      try {
        const ordersTotals = await this.$store.dispatch(
          "orders/GET_ORDERS_TOTAL",
          this.mainCompanyId
        );
        this.ordersTotal = ordersTotals;
      } catch (error) {
        console.log("fetchOrdersTotals error: ", error);
      }
    },
    preparePayment(event, item) {
      event.stopPropagation();
      this.$store.dispatch("orders/SET_SELECTED_ORDER", item).then(() => {
        this.$router.push({ name: "qr-sale" });
      });
    },
    prepareSaleLink() {
      this.$router.push({ name: "link-sales" });
    },
    onRowSelected(item) {
      // this.$store.dispatch("orders/SET_VIEW_ORDER", item[0]);
      /* this.$router.push({
        name: "order-details",
        params: { orderID: item[0]._id || item[0].id }
      }); */
      const orderDetailsRoute = this.$router.resolve({
        name: "order-details",
        params: { orderID: item[0]._id || item[0].id }
      });
      window.open(orderDetailsRoute.href, "_blank");
    },
    onRefreshed() {
      this.fetchOrdersTotals();
    },
    completePayment(order) {
      Swal.fire({
        text: this.$t("are_you_sure_to_complete_the_payment"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("FORM.CONFIRM"),
        cancelButtonText: this.$t("FORM.CANCEL")
      }).then(result => {
        if (result.value) {
          this.confirmCompletePayment(order._id);
        }
      });
    },
    confirmCompletePayment(order) {
      this.$store.commit("config/TOGGLE_OVERLAY", true);
      this.$store
        .dispatch("orders/COMPLETE_ORDER_PAYMENT", order)
        .then(() => {
          this.$store.commit("config/TOGGLE_OVERLAY", false);
          this.$root.$bvToast.toast(this.$t("COMMON.operation_performed"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
          this.$refs.listTable.refresh();
        })
        .catch(message => {
          this.$store.commit("config/TOGGLE_OVERLAY", false);
          this.$root.$bvToast.toast(message, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    },
    getCommissionPayerLabel(value) {
      return value
        ? this.$t("COMPANY.BRANCH")
        : this.$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER");
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    opacity: 0.7;
  }
}

.busy-spinner {
  width: 3rem;
  height: 3rem;
  margin: 5rem 0;
}
</style>
